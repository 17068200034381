@import "@styles/main.scss";
.root {
  position: relative;
}

.input {
  position: relative;
  display: flex;
  align-items: center;

  & > svg {
    width: 22px;
    min-width: 22px;
    height: 22px;
    min-height: 22px;
  }

  & input {
    border: 1px solid transparent;

    &:hover:not(:disabled),
    &:focus:not(:disabled),
    &:hover:not(:disabled) {
      border: 1px solid transparent;
    }
  }
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: $gap16;
}

.button {
  svg {
    width: 15px;
    height: 15px;
  }
}

.row {
  display: grid;
  column-gap: $gap4;
  grid-template-columns: repeat(3, 1fr);
  margin-bottom: $gap16;
}

.modal {
  position: absolute;
  top: 150%;
  width: fit-content;
  padding: $gap12;
  opacity: 0;
  z-index: 2;
  background: $color-white;
  box-shadow: $box-shadow-wide; // 0px 4px 12px rgba(0, 0, 0, 0.08);
  border-radius: 20px;
  pointer-events: none;
  transition: opacity $transitionTime;

  @include media-tablet {
    padding: $gap24 $gap12;
  }

  &.active {
    position: auto;
    opacity: 1;
    pointer-events: all;
    width: 402px;
  }
}

.label {
  @include type-button();
}
