@import "@styles/main.scss";
.root {
  font-family: $default-font;

  & > section {
    width: 100%;
    margin: 0 auto;
    margin-top: $gap72;

    @include media-tablet {
      margin-top: $gap24;
    }
  }

  & > section:last-child {
    margin-bottom: $gap112;

    @include media-tablet {
      margin-bottom: $gap64;
    }
  }
}

.benefitsSectionContent {
  color: $primary-text-inverse-color;
  background-color: $color-primary-redesign;
}

.divider {
  margin-top: $gap72;

  @include media-tablet {
    margin-top: $gap24;
  }
}
