@import "@styles/main.scss";
.root {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 19px 24px;

  border: 1px solid $color-secondary-redesign;
  box-shadow: $box-shadow-narrow;
  border-radius: $_border-radius-round;

  svg {
    width: 16px;
    height: 16px;
    margin-right: $gap16;
  }
}

.info {
  color: $color-primary-redesign;

  > *:first-child {
    margin-bottom: $gap4;
  }
}

.form {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  padding: $gap16;
  overflow-y: auto;

  .checkoutBtn {
    margin-top: $gap32;
  }
}

.searchBox {
  margin-bottom: $gap32;
}

.suggestionsMenu {
  display: block;
  position: static;
  height: 100%;
  padding: 0;
  box-shadow: none;
  border-radius: 0;
  border: none;
  overflow-y: auto;
}

.checkoutBtn {
  width: 100%;
  margin-top: $gap16;
}

.backButton {
  display: flex;
  align-items: center;
  width: fit-content;
  margin-bottom: $gap24;
  color: $color-primary-light-redesign;

  svg {
    margin-right: $gap8;
    width: 12px;
    height: 12px;

    path {
      stroke: $color-primary-light-redesign;
    }
  }
}

.analysisList {
  margin-bottom: $gap24;
}
