@import "@styles/main.scss";
.root {
  height: $_header-height;
  background: $header-bg;
  color: $header-color;
  display: flex;
  justify-content: center;
  margin-top: $_header-top-gap;
  box-sizing: content-box;
  position: sticky;
  z-index: 10;
  top: 0;

  @include media-tablet {
    height: auto;
    margin-top: 0;
    padding-top: $gap8;
  }
}

.navBar {
  display: flex;
  justify-content: space-between;
  width: 100%;
  z-index: 10;
  align-items: stretch;

  @include media-tablet {
    flex-flow: row wrap;
    width: 100%;
  }
}

.logo {
  order: 0;
  align-self: center;
}

.linksList {
  display: flex;
  align-items: center;
  white-space: nowrap;

  &::-webkit-scrollbar {
    display: none;
  }

  @include media-tablet {
    @include container-tablet-full-width();
    margin-top: $gap8;
    order: 3;
    width: 100%;
    overflow-x: auto;
    overflow-y: hidden;
    padding-bottom: $gap16;
    border-bottom: $header-links-border;
  }

  li {
    padding: 0 $gap20;
    height: $_menu-item-height;
    line-height: $_menu-item-height;
    margin: 0 $gap4;
    border-radius: calc($_menu-item-height / 2);

    &:first-child {
      margin-left: 0;
    }

    @include hover {
      cursor: pointer;
      border-radius: calc($_menu-item-height / 2);
      background: $header-links-hover-bg;
    }

    @include media-tablet {
      border: $header-links-border;
    }
  }
}

.active {
}

.iconsList {
  display: flex;
  align-items: center;
  order: 3;

  li {
    width: $_header-icon-item-size;
    height: $_header-icon-item-size;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    &:not(:last-child) {
      margin-right: $gap8;
    }

    @include media-tablet {
      width: $_header-icon-item-size-mobile;
      height: $_header-icon-item-size-mobile;
    }

    svg {
      width: $_header-icon-size;
      height: $_header-icon-size;

      path {
        fill: $header-color;
      }

      @include media-tablet {
        width: $_header-icon-size-mobile;
        height: $_header-icon-size-mobile;

        path {
          fill: $header-color;
        }
      }
    }

    @include hover {
      cursor: pointer;

      &:not(.authorized) {
        background: $header-links-hover-bg;
        border-radius: 50%;
      }
    }

    &.authorized {
      padding-right: 0;
    }
  }

  @include media-tablet {
    order: 2;
    max-width: 50%;
    padding: 0;
  }
}

.basketCount {
  position: absolute;
  display: inline-block;
  font-family: $default-font;
  font-size: 11px;
  color: $basket-count-color;
  background-color: $basket-count-bg;
  min-width: $_basket-count-width;
  height: $_basket-count-width;
  line-height:  $_basket-count-width;
  border-radius: calc($_basket-count-width / 2);
  text-align: center;
  right: $gap4;
  bottom: $gap2;
  box-sizing: content-box;

  @include media-tablet {
    transform: scale(0.8);
    right: 0;
    bottom: -$gap2;
  }
}
