@import "@styles/main.scss";
.root {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-flow: row nowrap;

  &.reverseOrder {
    flex-direction: row-reverse;
  }

  &,
  &.reverseOrder {
    @include media-tablet {
      flex-flow: column nowrap;
    }
  }
}

.image {
  position: relative;
  height: 100%;
  margin-left: $gap96;
  width: 100%;
  max-width: 427px;

  .reverseOrder & {
    margin-right: $gap96;
    margin-left: 0;
  }

  &,
  .reverseOrder & {
    @include media-tablet {
      margin: 0;
      padding: $gap32 0 0;
    }
  }
}

.text {
  flex-basis: 59%;

  .description {
    white-space: pre-line;
    opacity: 0.5;
  }

  & > *:not(:last-child) {
    margin-bottom: $gap12;
  }

  .stepNumber {
    color: $color-primary-redesign;
    display: block;
  }

  .title {
    margin-bottom: $gap12;
  }

  @include media-tablet {
    flex-basis: 100%;
    max-width: 100%;
    min-width: 100%;
  }
}
