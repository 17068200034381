@import "@styles/main.scss";
.root {
  max-width: 340px;

  & > *:first-child {
    margin-bottom: $gap24;
  }

  @include media-tablet {
    text-align: center;
    padding: 0 $gap44;
    margin-bottom: $gap68;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.title {
  margin-bottom: $gap8;
}

.text {
  @include media-tablet {
    @include type-button(); // font-size: 18px; line-height: 110%;
  }
}
