@import "@styles/main.scss";
.root {
  display: flex;
  flex-direction: column;
}

.divider {
  margin: $gap72 0;

  @include media-tablet {
    margin: $gap24 0;
    opacity: 0;
  }
}
