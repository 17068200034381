@import "@styles/main.scss";
.root {

}

.title {
  margin-bottom: $gap24;

  @include media-tablet {
    margin-bottom: 0;
  }
}

.quote {
  min-height: 150px;
}

.switcher {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 26px;
  height: 26px;
  margin: 0 26px;
  border: 1px solid $color-black;
  border-radius: 50%;

  svg {
    width: 18px;
  }
}

.iconLeft {
  transform: rotate(180deg);
}
