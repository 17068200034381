@import "@styles/main.scss";
.root {
  display: flex;
  justify-content: space-between;
  flex-flow: row nowrap;
  align-items: center;
  padding: $gap128 0;

  @include media-tablet {
    flex-flow: column nowrap;
    padding: $gap64 0;
  }
}
