@import "@styles/main.scss";
$first-column-width: 50%;
$second-column-width: 25%;
$third-column-width: 25%;
$_suggestions-menu-max-height: 400px;

.root {
  position: relative;
  display: grid;
  grid-template-columns: $first-column-width $second-column-width $third-column-width;
  grid-template-rows: auto auto;
  align-items: center;
  padding: $_hero-search-form-padding;
  border: 1px solid $color-input-border;
  box-shadow: $form-box-shadow;
  border-radius: 80px;
  box-sizing: border-box;

  > *:nth-child(1) {
    border-right: 1px solid $color-input-border;
  }

  @media (max-width: 1460px) {
    grid-template-columns: repeat(3, auto);
  }
}

.searchBox {
  margin-left: $gap16;
}

.datePicker {
  padding: 0 $gap16;

  input {
    &:focus:not(:disabled),
    &:hover:not(:disabled),
    &:active:not(:disabled) {
      border: 1px solid transparent;
      padding-left: $gap16;
      padding-right: $gap16;
    }
  }
}

.checkoutBtn {
  white-space: nowrap;

  & > span {
    @include type-button();
  }
}

ul.suggestionsMenu {
  top: calc($_hero-search-form-padding * 2 + $_input-height + $gap16);
  grid-column-end: 3;
  max-height: $_suggestions-menu-max-height;
  overflow-y: auto;

  @media (max-width: $demo-screen-width) {
    grid-column-end: 4;
    width: calc(100% + $_hero-search-form-padding);
  }
}
