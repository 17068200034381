@import "@styles/main.scss";
$column-gap: $gap24;
$all-researches-btn-width: 220px;

.root {
  display: grid;
  grid-template-columns: calc(100% - $all-researches-btn-width - $column-gap) auto;
  column-gap: $column-gap;
  position: relative;
  z-index: 4;

  @media (max-width: $demo-screen-width) {
    grid-template-columns: 1fr;
  }
}

.searchForm {
  grid-row-start: 2;
}

.allResearches {
  grid-row-start: 2;
  grid-column-start: 2;
  max-width: $all-researches-btn-width;

  & > :first-child {
    height: 100%;
  }

  @media (max-width: $demo-screen-width) {
    grid-row-start: auto;
    grid-column-start: auto;
    margin: 32px 0;
    max-width: unset;

    button {
      width: 100%;
    }
  }
}

.allResearches {
  // TODO SZ: refactor <Button /> instead of using hardcoded padding
  padding: $gap16 $gap2;

  span {
    @include type-button();
  }
}

.title {
  @include type-hero();
  margin-bottom: $gap16;
}

.text {
  margin-bottom: $gap42;
  white-space: pre-line;

  @include media-tablet {
    white-space: normal;
  }
}
