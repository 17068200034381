@import "@styles/main.scss";
.root {
  @include container-layout;
  position: relative;
  min-height: 100vh;
  width: 100%;
  overflow-x: hidden;
}

.main {
}

